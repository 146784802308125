import { Link } from 'gatsby';
import React from 'react';
import { Layout } from '../../../components/Layout';
import BlogCategories from '../../../components/BlogCategories';

const CondominiumOwnership = () => (
  <Layout
    title="Articles about surveying and RPR (Real Property Report) requirements relating to municipal bylaws permits and related issues."
    description="Want to learn more about the bylaws in Calgary, Red Deer and Edmonton and how they effect an RPR / Real Property Reports? This a in depth blog style resource to help you understand the details of the many permit and by-law issues."
    keywords=""
  >
    <main>
      <BlogCategories />

      <h1>Category: Condominium Ownership</h1>
      <ul>
        <li>
          <Link to="/articles/bare-land-condo-vs-conventional-condo">
            Bare Land Condo vs Conventional Condo
          </Link>
          <br />
          Bare land condos offer ownership of land and structure with more control but require
          maintenance. Conventional condos provide convenience with shared upkeep and higher fees.
          The choice depends on whether you prioritize independence or ease of living. Consulting a
          professional can help determine the best fit.
        </li>
      </ul>
    </main>
  </Layout>
);

export default CondominiumOwnership;
